import { PropertyFilterProperty } from '@amzn/awsui-collection-hooks/dist/cjs/interfaces';
import { DateRangePickerProps } from '@amzn/awsui-components-react';
import moment from 'moment';
import CopyText from '../../utils/copy-text';

export const i18nStrings = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next month',
  previousMonthAriaLabel: 'Previous month',
  customRelativeRangeDurationLabel: 'Duration',
  customRelativeRangeDurationPlaceholder: 'Enter duration',
  customRelativeRangeOptionLabel: 'Custom range',
  customRelativeRangeOptionDescription: 'Set a custom range in the past',
  customRelativeRangeUnitLabel: 'Unit of time',
  formatRelativeRange: (e) => {
    const timeLogs = 1 === e.amount ? e.unit : e.unit + 's';
    return `Last ${e.amount} ${timeLogs}`;
  },
  formatUnit: (e, timeLogs) => (1 === timeLogs ? e : e + 's'),
  relativeModeTitle: 'Relative range',
  absoluteModeTitle: 'Absolute range',
  relativeRangeSelectionHeading: 'Choose a range',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startTimeLabel: 'Start time',
  endTimeLabel: 'End time',
  clearButtonLabel: 'Clear',
  cancelButtonLabel: 'Cancel',
  applyButtonLabel: 'Apply',
};

export const relativeOptions: ReadonlyArray<DateRangePickerProps.RelativeOption> =
  [
    {
      key: 'previous-5-hours',
      amount: 5,
      unit: 'hour',
      type: 'relative',
    },
    {
      key: 'previous-20-hours',
      amount: 20,
      unit: 'hour',
      type: 'relative',
    },
    {
      key: 'previous-3-days',
      amount: 3,
      unit: 'day',
      type: 'relative',
    },
    {
      key: 'previous-1-week',
      amount: 1,
      unit: 'week',
      type: 'relative',
    },
    {
      key: 'previous-1-month',
      amount: 1,
      unit: 'month',
      type: 'relative',
    },
  ];

export const columnDefinitions = [
  {
    id: 'status',
    header: 'Status',
    cell: (e) =>
      (e.status === '3-Done' ? 'Done' : e.status || '-') +
      ' ' +
      (e.message || ''),
    sortingField: 'status',
  },
  {
    id: 'download',
    header: 'Download',
    cell: (e) => {
      if (e.status === '3-Done' && e.s3Path) {
        const path = 's3://' + e.s3Path[0].split('/').slice(0, -1).join('/');
        const command = `aws s3 cp ${path} . --recursive`;
        return (
          <CopyText
            copyText={command}
            copyButtonLabel="Logs"
            successText="CLI command copied"
            errorText="CLI command failed to copy"
          />
        );
      } else return '-';
    },
  },
  {
    id: 'latency_in_ms',
    header: 'Latency (in ms)',
    cell: (e) => parseInt(e.latency_in_ms) || '-',
    sortingField: 'latency_in_ms',
  },
  {
    id: 'requester',
    header: 'Requested By',
    cell: (e) => e.requester,
    sortingField: 'requester',
  },
];

export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'log_hour', label: 'Log Hour' },
      { id: 'status', label: 'Status' },
      { id: 'log_group', label: 'Log Group' },
      { id: 'latency_in_ms', label: 'Latency' },
      { id: 'download', label: 'Download' },
      { id: 'requester', label: 'Requested By' },
    ],
  },
];

export const FILTERING_PROPERTIES: readonly PropertyFilterProperty[] = [
  {
    propertyLabel: 'Log Hour',
    key: 'log_hour',
    groupValuesLabel: 'Log Hour Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Status',
    key: 'status',
    groupValuesLabel: 'Status Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Log Group',
    key: 'log_group',
    groupValuesLabel: 'Log Group Values',
    operators: [':', '!:', '=', '!='],
  },
  {
    propertyLabel: 'Latency',
    key: 'latency_in_ms',
    groupValuesLabel: 'Latency Values',
    operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
  },
  {
    propertyLabel: 'Requested By',
    key: 'requester',
    groupValuesLabel: 'Requested By Values',
    operators: [':', '!:', '=', '!='],
  },
];

export const PAGE_SIZE_OPTIONS = [
  { value: 50, label: '50 Log Requests' },
  { value: 100, label: '100 Log Requests' },
  { value: 500, label: '500 Log Requests' },
  { value: 1000, label: '1000 Log Requests' },
  { value: 8000, label: '8000 Log Requests' },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 1000,
  visibleContent: [
    'log_hour',
    'log_group',
    'status',
    'latency_in_ms',
    'download',
    'requester',
  ],
  wraplines: true,
};

export const globalRequestColumnDefinitions = [
  {
    id: 'domain_id',
    header: 'Domain Id',
    cell: (e) => e.domain_id,
    sortingField: 'domain_id',
  },
  {
    id: 'log_group',
    header: 'Log Group',
    cell: (e) => e.hourly_timestamp.split(' ')[1] || '-',
    sortingField: 'log_group',
  },
  {
    id: 'requester',
    header: 'Requested By',
    cell: (e) => e.requester,
    sortingField: 'requester',
  },
  {
    id: 'status',
    header: 'Status',
    cell: (e) => e.status,
    sortingField: 'status',
  },
  {
    id: 'message',
    header: 'Message',
    cell: (e) => e.message || '-',
    sortingField: 'message',
  },
  {
    id: 'last_process_timestamp',
    header: 'Last Process Timestamp',
    cell: (e) => e.last_process_timestamp || '-',
    sortingField: 'last_process_timestamp',
  },
];

export const GLOBAL_QUEUE_VISIBLE_CONTENT_OPTIONS = [
  {
    label: 'Options',
    options: [
      { id: 'domain_id', label: 'domain_id' },
      { id: 'log_hour', label: 'Log Hour' },
      { id: 'log_group', label: 'Log Group' },
      { id: 'requester', label: 'requester' },
      { id: 'status', label: 'status' },
      { id: 'message', label: 'message' },
      { id: 'last_process_timestamp', label: 'Last Process Timestamp' },
    ],
  },
];

export const GLOBAL_QUEUE_FILTERING_PROPERTIES: readonly PropertyFilterProperty[] =
  [
    {
      propertyLabel: 'Domain Id',
      key: 'domain_id',
      groupValuesLabel: 'Domain Id Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Log Hour',
      key: 'log_hour',
      groupValuesLabel: 'Log Hour Values',
      operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
    },
    {
      propertyLabel: 'Log Group',
      key: 'log_group',
      groupValuesLabel: 'Log Group Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Requester',
      key: 'requester',
      groupValuesLabel: 'Requester Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Status',
      key: 'status',
      groupValuesLabel: 'Status Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Message',
      key: 'message',
      groupValuesLabel: 'Message Values',
      operators: [':', '!:', '=', '!='],
    },
    {
      propertyLabel: 'Last Process Timestamp',
      key: 'last_process_timestamp',
      groupValuesLabel: 'Last Process Timestamp Values',
      operators: [':', '!:', '=', '!=', '>', '>=', '<', '<='],
    },
  ];

export const GLOBAL_QUEUE_PAGE_SIZE_OPTIONS = [
  { value: 50, label: '50 Log Requests' },
  { value: 100, label: '100 Log Requests' },
  { value: 500, label: '500 Log Requests' },
  { value: 1000, label: '1000 Log Requests' },
  { value: 8000, label: '8000 Log Requests' },
];

export const GLOBAL_DEFAULT_PREFERENCES = {
  pageSize: 1000,
  visibleContent: [
    'domain_id',
    'log_hour',
    'log_group',
    'requester',
    'status',
    'message',
    'last_process_timestamp',
  ],
  wraplines: true,
};

export const esOsVersionOptions = [
  {
    label: 'ES version',
    options: [
      { label: '7.10', value: 'ES_7_10' },
      { label: '7.9', value: 'ES_7_9' },
      { label: '7.8', value: 'ES_7_8' },
      { label: '7.7', value: 'ES_7_7' },
      { label: '7.4', value: 'ES_7_4' },
      { label: '7.1', value: 'ES_7_1' },
      { label: '6.8', value: 'ES_6_8' },
      { label: '6.7', value: 'ES_6_7' },
      { label: '6.5', value: 'ES_6_5' },
      { label: '6.4', value: 'ES_6_4' },
      { label: '6.3', value: 'ES_6_3' },
      { label: '6.2', value: 'ES_6_2' },
      { label: '6.0', value: 'ES_6_0' },
      { label: '5.6', value: 'ES_5_6' },
      { label: '5.5', value: 'ES_5_5' },
      { label: '5.3', value: 'ES_5_3' },
      { label: '5.1', value: 'ES_5_1' },
      { label: '2.3', value: 'ES_2_3' },
    ],
  },
  {
    label: 'OS version',
    options: [
      { label: '1.0', value: 'OS_1_0' },
      { label: '1.1', value: 'OS_1_1' },
      { label: '1.2', value: 'OS_1_2' },
      { label: '1.3', value: 'OS_1_3' },
      { label: '2.3', value: 'OS_2_3' },
      { label: '2.5', value: 'OS_2_5' },
      { label: '2.7', value: 'OS_2_7' },
      { label: '2.9', value: 'OS_2_9' },
      { label: '2.11', value: 'OS_2_11' },
      { label: '2.13', value: 'OS_2_13' },
      { label: '2.15', value: 'OS_2_15' },
      { label: '2.17', value: 'OS_2_17' },
    ],
  },
];

export const logGroupOptions = [
  {
    label: 'ServiceProxy',
    options: [
      { label: 'haproxy.log', value: 'ServiceProxyAMI/ES2/Alpha/haproxy.log' },
    ],
  },
  {
    label: 'Kibana',
    options: [],
  },
  {
    label: 'ElasticSearch',
    options: [
      {
        label: 'auth.general.log',
        value: 'ES_2_3AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_2_3AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_2_3AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_2_3AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_indexing_slowlog.log',
        value: 'ES_2_3AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log',
      },
      {
        label: 'elasticsearch_index_search_slowlog.log',
        value: 'ES_2_3AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_2_3AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_2_3AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log-remover.log',
        value: 'ES_2_3AMI/ES2/Alpha/log-remover.log',
      },
      {
        label: 'log-rotator.log',
        value: 'ES_2_3AMI/ES2/Alpha/log-rotator.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_2_3AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_2_3AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      { label: 'service_log', value: 'ES_2_3AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_2_3AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_2_3AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_2_3AMI/ES2/Alpha/supervisord.pa.log',
      },
      {
        label: 'website-log-pusher.log',
        value: 'ES_2_3AMI/ES2/Alpha/website-log-pusher.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_2_3AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_2_3AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'auth.general.log',
        value: 'ES_5_1AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_5_1AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_5_1AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_5_1AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_indexing_slowlog.log',
        value: 'ES_5_1AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log',
      },
      {
        label: 'elasticsearch_index_search_slowlog.log',
        value: 'ES_5_1AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_5_1AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_5_1AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_5_1AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log-remover.log',
        value: 'ES_5_1AMI/ES2/Alpha/log-remover.log',
      },
      {
        label: 'log-rotator.log',
        value: 'ES_5_1AMI/ES2/Alpha/log-rotator.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_5_1AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_5_1AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      { label: 'service_log', value: 'ES_5_1AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_5_1AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_5_1AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_5_1AMI/ES2/Alpha/supervisord.pa.log',
      },
      {
        label: 'website-log-pusher.log',
        value: 'ES_5_1AMI/ES2/Alpha/website-log-pusher.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_5_1AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_5_1AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'auth.general.log',
        value: 'ES_5_3AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_5_3AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_5_3AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_5_3AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_indexing_slowlog.log',
        value: 'ES_5_3AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log',
      },
      {
        label: 'elasticsearch_index_search_slowlog.log',
        value: 'ES_5_3AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_5_3AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_5_3AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_5_3AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log-remover.log',
        value: 'ES_5_3AMI/ES2/Alpha/log-remover.log',
      },
      {
        label: 'log-rotator.log',
        value: 'ES_5_3AMI/ES2/Alpha/log-rotator.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_5_3AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_5_3AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      { label: 'service_log', value: 'ES_5_3AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_5_3AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_5_3AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_5_3AMI/ES2/Alpha/supervisord.pa.log',
      },
      {
        label: 'website-log-pusher.log',
        value: 'ES_5_3AMI/ES2/Alpha/website-log-pusher.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_5_3AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_5_3AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'auth.general.log',
        value: 'ES_5_5AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_5_5AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_5_5AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_5_5AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_indexing_slowlog.log',
        value: 'ES_5_5AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log',
      },
      {
        label: 'elasticsearch_index_search_slowlog.log',
        value: 'ES_5_5AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_5_5AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_5_5AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_5_5AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log-remover.log',
        value: 'ES_5_5AMI/ES2/Alpha/log-remover.log',
      },
      {
        label: 'log-rotator.log',
        value: 'ES_5_5AMI/ES2/Alpha/log-rotator.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_5_5AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_5_5AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      { label: 'service_log', value: 'ES_5_5AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_5_5AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_5_5AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_5_5AMI/ES2/Alpha/supervisord.pa.log',
      },
      {
        label: 'website-log-pusher.log',
        value: 'ES_5_5AMI/ES2/Alpha/website-log-pusher.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_5_5AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_5_5AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'auth.general.log',
        value: 'ES_5_6AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_5_6AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_5_6AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_5_6AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_indexing_slowlog.log',
        value: 'ES_5_6AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log',
      },
      {
        label: 'elasticsearch_index_search_slowlog.log',
        value: 'ES_5_6AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_5_6AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_5_6AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_5_6AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log-remover.log',
        value: 'ES_5_6AMI/ES2/Alpha/log-remover.log',
      },
      {
        label: 'log-rotator.log',
        value: 'ES_5_6AMI/ES2/Alpha/log-rotator.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_5_6AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_5_6AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      { label: 'service_log', value: 'ES_5_6AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_5_6AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_5_6AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_5_6AMI/ES2/Alpha/supervisord.pa.log',
      },
      {
        label: 'website-log-pusher.log',
        value: 'ES_5_6AMI/ES2/Alpha/website-log-pusher.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_5_6AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_5_6AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_6_0AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_6_0AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_6_0AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_6_0AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_6_0AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_6_0AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_indexing_slowlog.log',
        value: 'ES_6_0AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log',
      },
      {
        label: 'elasticsearch_index_search_slowlog.log',
        value: 'ES_6_0AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_6_0AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_6_0AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_6_0AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log-remover.log',
        value: 'ES_6_0AMI/ES2/Alpha/log-remover.log',
      },
      {
        label: 'log-rotator.log',
        value: 'ES_6_0AMI/ES2/Alpha/log-rotator.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_6_0AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_6_0AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_6_0AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_6_0AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_6_0AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      { label: 'service_log', value: 'ES_6_0AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_6_0AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_6_0AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_6_0AMI/ES2/Alpha/supervisord.pa.log',
      },
      {
        label: 'website-log-pusher.log',
        value: 'ES_6_0AMI/ES2/Alpha/website-log-pusher.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_6_0AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_6_0AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_6_2AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_6_2AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_6_2AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_6_2AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_6_2AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_6_2AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_indexing_slowlog.log',
        value: 'ES_6_2AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log',
      },
      {
        label: 'elasticsearch_index_search_slowlog.log',
        value: 'ES_6_2AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_6_2AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_6_2AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_6_2AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log-remover.log',
        value: 'ES_6_2AMI/ES2/Alpha/log-remover.log',
      },
      {
        label: 'log-rotator.log',
        value: 'ES_6_2AMI/ES2/Alpha/log-rotator.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_6_2AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_6_2AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_6_2AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_6_2AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_6_2AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      { label: 'service_log', value: 'ES_6_2AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_6_2AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_6_2AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_6_2AMI/ES2/Alpha/supervisord.pa.log',
      },
      {
        label: 'website-log-pusher.log',
        value: 'ES_6_2AMI/ES2/Alpha/website-log-pusher.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_6_2AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_6_2AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_6_3AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'ReactionWheel.log',
        value: 'ES_6_3AMI/ES2/Alpha/ReactionWheel.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_6_3AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_6_3AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_6_3AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_6_3AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_6_3AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_indexing_slowlog.log',
        value: 'ES_6_3AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log',
      },
      {
        label: 'elasticsearch_index_search_slowlog.log',
        value: 'ES_6_3AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_6_3AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_6_3AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_6_3AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log-remover.log',
        value: 'ES_6_3AMI/ES2/Alpha/log-remover.log',
      },
      {
        label: 'log-rotator.log',
        value: 'ES_6_3AMI/ES2/Alpha/log-rotator.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_6_3AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_6_3AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_6_3AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_6_3AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_6_3AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      {
        label: 'reactionwheel_application_metrics.log',
        value: 'ES_6_3AMI/ES2/Alpha/reactionwheel_application_metrics.log',
      },
      { label: 'service_log', value: 'ES_6_3AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_6_3AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_6_3AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_6_3AMI/ES2/Alpha/supervisord.pa.log',
      },
      {
        label: 'website-log-pusher.log',
        value: 'ES_6_3AMI/ES2/Alpha/website-log-pusher.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_6_3AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_6_3AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'auth.general.log',
        value: 'ES_6_4AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_6_4AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_6_4AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_6_4AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_6_4AMI/ES2/Alpha/elasticsearch.log',
      },
      { label: 'es.metrics.log', value: 'ES_6_4AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_6_4AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_6_4AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_6_4AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_6_4AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_6_4AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_6_4AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_6_4AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      { label: 'service_log', value: 'ES_6_4AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_6_4AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_6_4AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_6_4AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_6_4AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_6_4AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_6_5AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_6_5AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_6_5AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_6_5AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_6_5AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_6_5AMI/ES2/Alpha/elasticsearch.log',
      },
      { label: 'es.metrics.log', value: 'ES_6_5AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_6_5AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_6_5AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_6_5AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_6_5AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_6_5AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_6_5AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_6_5AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      { label: 'service_log', value: 'ES_6_5AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_6_5AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_6_5AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_6_5AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_6_5AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_6_5AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_6_7AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'ReactionWheel.log',
        value: 'ES_6_7AMI/ES2/Alpha/ReactionWheel.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_6_7AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_6_7AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'aws-requests.general',
        value: 'ES_6_7AMI/ES2/Alpha/aws-requests.general',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_6_7AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_6_7AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_6_7AMI/ES2/Alpha/elasticsearch.log',
      },
      { label: 'es.metrics.log', value: 'ES_6_7AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_6_7AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_6_7AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_6_7AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_6_7AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_6_7AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'opendistro_security_audit.log',
        value: 'ES_6_7AMI/ES2/Alpha/opendistro_security_audit.log',
      },
      {
        label: 'opendistro_security_audit_service.log',
        value: 'ES_6_7AMI/ES2/Alpha/opendistro_security_audit_service.log',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_6_7AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_6_7AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      {
        label: 'reactionwheel_application_metrics.log',
        value: 'ES_6_7AMI/ES2/Alpha/reactionwheel_application_metrics.log',
      },
      {
        label: 'security.saml.log',
        value: 'ES_6_7AMI/ES2/Alpha/security.saml.log',
      },
      { label: 'service_log', value: 'ES_6_7AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_6_7AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_6_7AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_6_7AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_6_7AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_6_7AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_6_8AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'ReactionWheel.log',
        value: 'ES_6_8AMI/ES2/Alpha/ReactionWheel.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_6_8AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_6_8AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'aws-requests.general',
        value: 'ES_6_8AMI/ES2/Alpha/aws-requests.general',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_6_8AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_6_8AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_6_8AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_warm_slowlog.log',
        value: 'ES_6_8AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_6_8AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_6_8AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_6_8AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_6_8AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_6_8AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_6_8AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'opendistro_security_audit.log',
        value: 'ES_6_8AMI/ES2/Alpha/opendistro_security_audit.log',
      },
      {
        label: 'opendistro_security_audit_service.log',
        value: 'ES_6_8AMI/ES2/Alpha/opendistro_security_audit_service.log',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_6_8AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_6_8AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      {
        label: 'reactionwheel_application_metrics.log',
        value: 'ES_6_8AMI/ES2/Alpha/reactionwheel_application_metrics.log',
      },
      {
        label: 'security.saml.log',
        value: 'ES_6_8AMI/ES2/Alpha/security.saml.log',
      },
      { label: 'service_log', value: 'ES_6_8AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_6_8AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_6_8AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_6_8AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_6_8AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_6_8AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_7_10AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'ReactionWheel.log',
        value: 'ES_7_10AMI/ES2/Alpha/ReactionWheel.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_7_10AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_7_10AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'aws-requests.general',
        value: 'ES_7_10AMI/ES2/Alpha/aws-requests.general',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_7_10AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_7_10AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_7_10AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_warm_slowlog.log',
        value: 'ES_7_10AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_7_10AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_7_10AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_7_10AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_7_10AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_7_10AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_7_10AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'opendistro_security_audit_service.log',
        value: 'ES_7_10AMI/ES2/Alpha/opendistro_security_audit_service.log',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_7_10AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_7_10AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      {
        label: 'reactionwheel_application_metrics.log',
        value: 'ES_7_10AMI/ES2/Alpha/reactionwheel_application_metrics.log',
      },
      { label: 'service_log', value: 'ES_7_10AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_7_10AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_7_10AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_7_10AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_7_10AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_7_10AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_7_1AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'ReactionWheel.log',
        value: 'ES_7_1AMI/ES2/Alpha/ReactionWheel.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_7_1AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_7_1AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'aws-requests.general',
        value: 'ES_7_1AMI/ES2/Alpha/aws-requests.general',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_7_1AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_7_1AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_7_1AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_warm_slowlog.log',
        value: 'ES_7_1AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_7_1AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_7_1AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_7_1AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_7_1AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_7_1AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_7_1AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'opendistro_security_audit.log',
        value: 'ES_7_1AMI/ES2/Alpha/opendistro_security_audit.log',
      },
      {
        label: 'opendistro_security_audit_service.log',
        value: 'ES_7_1AMI/ES2/Alpha/opendistro_security_audit_service.log',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_7_1AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_7_1AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      {
        label: 'reactionwheel_application_metrics.log',
        value: 'ES_7_1AMI/ES2/Alpha/reactionwheel_application_metrics.log',
      },
      {
        label: 'security.saml.log',
        value: 'ES_7_1AMI/ES2/Alpha/security.saml.log',
      },
      { label: 'service_log', value: 'ES_7_1AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_7_1AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_7_1AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_7_1AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_7_1AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_7_1AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_7_4AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'ReactionWheel.log',
        value: 'ES_7_4AMI/ES2/Alpha/ReactionWheel.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_7_4AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_7_4AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'aws-requests.general',
        value: 'ES_7_4AMI/ES2/Alpha/aws-requests.general',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_7_4AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_7_4AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_7_4AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_warm_slowlog.log',
        value: 'ES_7_4AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_7_4AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_7_4AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_7_4AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_7_4AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_7_4AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_7_4AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'opendistro_security_audit.log',
        value: 'ES_7_4AMI/ES2/Alpha/opendistro_security_audit.log',
      },
      {
        label: 'opendistro_security_audit_service.log',
        value: 'ES_7_4AMI/ES2/Alpha/opendistro_security_audit_service.log',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_7_4AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_7_4AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      {
        label: 'reactionwheel_application_metrics.log',
        value: 'ES_7_4AMI/ES2/Alpha/reactionwheel_application_metrics.log',
      },
      {
        label: 'security.saml.log',
        value: 'ES_7_4AMI/ES2/Alpha/security.saml.log',
      },
      { label: 'service_log', value: 'ES_7_4AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_7_4AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_7_4AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_7_4AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_7_4AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_7_4AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_7_7AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'ReactionWheel.log',
        value: 'ES_7_7AMI/ES2/Alpha/ReactionWheel.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_7_7AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_7_7AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'aws-requests.general',
        value: 'ES_7_7AMI/ES2/Alpha/aws-requests.general',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_7_7AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_7_7AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_7_7AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_warm_slowlog.log',
        value: 'ES_7_7AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_7_7AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_7_7AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_7_7AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_7_7AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_7_7AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_7_7AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'opendistro_security_audit.log',
        value: 'ES_7_7AMI/ES2/Alpha/opendistro_security_audit.log',
      },
      {
        label: 'opendistro_security_audit_service.log',
        value: 'ES_7_7AMI/ES2/Alpha/opendistro_security_audit_service.log',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_7_7AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_7_7AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      {
        label: 'reactionwheel_application_metrics.log',
        value: 'ES_7_7AMI/ES2/Alpha/reactionwheel_application_metrics.log',
      },
      {
        label: 'security.saml.log',
        value: 'ES_7_7AMI/ES2/Alpha/security.saml.log',
      },
      { label: 'service_log', value: 'ES_7_7AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_7_7AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_7_7AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_7_7AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_7_7AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_7_7AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_7_8AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'ReactionWheel.log',
        value: 'ES_7_8AMI/ES2/Alpha/ReactionWheel.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_7_8AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_7_8AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'aws-requests.general',
        value: 'ES_7_8AMI/ES2/Alpha/aws-requests.general',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_7_8AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_7_8AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_7_8AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_warm_slowlog.log',
        value: 'ES_7_8AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_7_8AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_7_8AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_7_8AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_7_8AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_7_8AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_7_8AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'opendistro_security_audit_service.log',
        value: 'ES_7_8AMI/ES2/Alpha/opendistro_security_audit_service.log',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_7_8AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_7_8AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      {
        label: 'reactionwheel_application_metrics.log',
        value: 'ES_7_8AMI/ES2/Alpha/reactionwheel_application_metrics.log',
      },
      {
        label: 'security.saml.log',
        value: 'ES_7_8AMI/ES2/Alpha/security.saml.log',
      },
      { label: 'service_log', value: 'ES_7_8AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_7_8AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_7_8AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_7_8AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_7_8AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_7_8AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      {
        label: 'PerformanceAnalyzer.log',
        value: 'ES_7_9AMI/ES2/Alpha/PerformanceAnalyzer.log',
      },
      {
        label: 'ReactionWheel.log',
        value: 'ES_7_9AMI/ES2/Alpha/ReactionWheel.log',
      },
      {
        label: 'auth.general.log',
        value: 'ES_7_9AMI/ES2/Alpha/auth.general.log',
      },
      {
        label: 'auth.metrics.log',
        value: 'ES_7_9AMI/ES2/Alpha/auth.metrics.log',
      },
      {
        label: 'aws-requests.general',
        value: 'ES_7_9AMI/ES2/Alpha/aws-requests.general',
      },
      {
        label: 'cert-reload.general.log',
        value: 'ES_7_9AMI/ES2/Alpha/cert-reload.general.log',
      },
      {
        label: 'check-for-hung-es.general.log',
        value: 'ES_7_9AMI/ES2/Alpha/check-for-hung-es.general.log',
      },
      {
        label: 'elasticsearch.log',
        value: 'ES_7_9AMI/ES2/Alpha/elasticsearch.log',
      },
      {
        label: 'elasticsearch_index_warm_slowlog.log',
        value: 'ES_7_9AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log',
      },
      { label: 'es.metrics.log', value: 'ES_7_9AMI/ES2/Alpha/es.metrics.log' },
      {
        label: 'es_deprecation.log',
        value: 'ES_7_9AMI/ES2/Alpha/es_deprecation.log',
      },
      {
        label: 'jcmd_class_histogram.log',
        value: 'ES_7_9AMI/ES2/Alpha/jcmd_class_histogram.log',
      },
      {
        label: 'log_truncate.log',
        value: 'ES_7_9AMI/ES2/Alpha/log_truncate.log',
      },
      {
        label: 'metrics-db-files-concater.log',
        value: 'ES_7_9AMI/ES2/Alpha/metrics-db-files-concater.log',
      },
      {
        label: 'metricsdb.concat.tar',
        value: 'ES_7_9AMI/ES2/Alpha/metricsdb.concat.tar',
      },
      {
        label: 'opendistro_security_audit_service.log',
        value: 'ES_7_9AMI/ES2/Alpha/opendistro_security_audit_service.log',
      },
      {
        label: 'pa.agent.stats.log',
        value: 'ES_7_9AMI/ES2/Alpha/pa.agent.stats.log',
      },
      {
        label: 'pa.plugin.stats.log',
        value: 'ES_7_9AMI/ES2/Alpha/pa.plugin.stats.log',
      },
      {
        label: 'reactionwheel_application_metrics.log',
        value: 'ES_7_9AMI/ES2/Alpha/reactionwheel_application_metrics.log',
      },
      {
        label: 'security.saml.log',
        value: 'ES_7_9AMI/ES2/Alpha/security.saml.log',
      },
      { label: 'service_log', value: 'ES_7_9AMI/ES2/Alpha/service_log' },
      {
        label: 'supervisord.es.log',
        value: 'ES_7_9AMI/ES2/Alpha/supervisord.es.log',
      },
      {
        label: 'supervisord.log',
        value: 'ES_7_9AMI/ES2/Alpha/supervisord.log',
      },
      {
        label: 'supervisord.pa.log',
        value: 'ES_7_9AMI/ES2/Alpha/supervisord.pa.log',
      },
      { 
        label: 'read-node-discovery-file.general.log', 
        value: 'ES_7_9AMI/ES2/Alpha/read-node-discovery-file.general.log' 
      },
      { 
        label: 'write-node-discovery-file.general.log', 
        value: 'ES_7_9AMI/ES2/Alpha/write-node-discovery-file.general.log' 
      },

      // OS versioned loggroups added by function
    ],
  },
  {
    label: 'SDPDAgent',
    options: [
      {
        label: 'sdpd_agent.log',
        value: 'SDPDAgentAMI/ES2/Alpha/sdpd_agent.log',
      },
      {
        label: 'sdpd_metrics.log',
        value: 'SDPDAgentAMI/ES2/Alpha/sdpd_metrics.log',
      },
      { label: 'service_log', value: 'SDPDAgentAMI/ES2/Alpha/service_log' },
    ],
  },
  {
    label: 'LogPusher',
    options: [
      {
        label: 'cw.agent.log',
        value: 'LogPusherAMI/ES2/Alpha/cw.agent.log'
      },
      {
        label: 'cwagent.metrics.log',
        value: 'LogPusherAMI/ES2/Alpha/cwagent.metrics.log'
      },
    ],
  },
  {
    label: 'OnDemandData',
    options: [
      {
        label: 'elb.log',
        value: 'ELB'
      }
    ],
  },
];

const addKibanaESVersionLogGroups = (EsVersion) => {
  const kibanaLogGroups = [
    {
      label: 'check-for-hung-kibana.general.log',
      value: `Kibana_${EsVersion}AMI/ES2/Alpha/check-for-hung-kibana.general.log`,
    },
    {
      label: 'kibana.log',
      value: `Kibana_${EsVersion}AMI/ES2/Alpha/kibana.log`,
    },
    {
      label: 'service_log',
      value: `Kibana_${EsVersion}AMI/ES2/Alpha/service_log`,
    },
    {
      label: 'supervisord.kibana.log',
      value: `Kibana_${EsVersion}AMI/ES2/Alpha/supervisord.kibana.log`,
    },
    {
      label: 'supervisord.log',
      value: `Kibana_${EsVersion}AMI/ES2/Alpha/supervisord.log`,
    },
  ];
  if (['6_3', '6_2', '6_0', '5_6', '5_5', '5_3', '5_1'].includes(EsVersion)) {
    kibanaLogGroups.push(
      ...[
        {
          label: 'log-remover.log',
          value: `Kibana_${EsVersion}AMI/ES2/Alpha/log-remover.log`,
        },
        {
          label: 'website-log-pusher.log',
          value: `Kibana_${EsVersion}AMI/ES2/Alpha/website-log-pusher.log`,
        },
      ]
    );
  } else {
    kibanaLogGroups.push(
      ...[
        {
          label: 'aws-requests.general',
          value: `Kibana_${EsVersion}AMI/ES2/Alpha/aws-requests.general`,
        },
      ]
    );
  }
  logGroupOptions
    .find((x) => x.label === 'Kibana')
    .options.push(...kibanaLogGroups);
};

const addKibanaOSVersionLogGroups = (OsVersion) => {
  const kibanaLogGroups = [
    {
      label: 'aws-requests.general',
      value: `OSD_${OsVersion}AMI/ES2/Alpha/aws-requests.general`,
    },
    {
      label: 'check-for-hung-kibana.general.log',
      value: `OSD_${OsVersion}AMI/ES2/Alpha/check-for-hung-kibana.general.log`,
    },
    { label: 'kibana.log', value: `OSD_${OsVersion}AMI/ES2/Alpha/kibana.log` },
    {
      label: 'service_log',
      value: `OSD_${OsVersion}AMI/ES2/Alpha/service_log`,
    },
    {
      label: 'supervisord.kibana.log',
      value: `OSD_${OsVersion}AMI/ES2/Alpha/supervisord.kibana.log`,
    },
    {
      label: 'supervisord.log',
      value: `OSD_${OsVersion}AMI/ES2/Alpha/supervisord.log`,
    },
  ];
  logGroupOptions
    .find((x) => x.label === 'Kibana')
    .options.push(...kibanaLogGroups);
};

const supportedKibanaESVersions = [
  '5_1',
  '5_3',
  '5_5',
  '5_6',
  '6_0',
  '6_2',
  '6_3',
  '6_4',
  '6_5',
  '6_7',
  '6_8',
  '7_1',
  '7_4',
  '7_7',
  '7_8',
  '7_9',
  '7_10',
];
const supportedKibanaOSVersions = ['1_0', '1_1', '1_2', '1_3', '2_3', '2_5', '2_7', "2_9", '2_11', '2_13', '2_15', '2_17'];

for (const esVersion of supportedKibanaESVersions) {
  addKibanaESVersionLogGroups(esVersion);
}
for (const osVersion of supportedKibanaOSVersions) {
  addKibanaOSVersionLogGroups(osVersion);
}

const addElasticSearchOSVersionLogGroups = (OsVersion) => {
  const esLogGroups = [
    {
      label: 'PerformanceAnalyzer.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/PerformanceAnalyzer.log`,
    },
    {
      label: 'ReactionWheel.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/ReactionWheel.log`,
    },
    {
      label: 'auth.general.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/auth.general.log`,
    },
    {
      label: 'auth.metrics.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/auth.metrics.log`,
    },
    {
      label: 'aws-requests.general',
      value: `OS_${OsVersion}AMI/ES2/Alpha/aws-requests.general`,
    },
    {
      label: 'cert-reload.general.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/cert-reload.general.log`,
    },
    {
      label: 'check-for-hung-es.general.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/check-for-hung-es.general.log`,
    },
    {
      label: 'elasticsearch.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/elasticsearch.log`,
    },
    {
      label: 'elasticsearch_index_warm_slowlog.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log`,
    },
    {
      label: 'es.metrics.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/es.metrics.log`,
    },
    {
      label: 'es_deprecation.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/es_deprecation.log`,
    },
    {
      label: 'jcmd_class_histogram.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/jcmd_class_histogram.log`,
    },
    {
      label: 'log_truncate.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/log_truncate.log`,
    },
    {
      label: 'metrics-db-files-concater.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/metrics-db-files-concater.log`,
    },
    {
      label: 'metricsdb.concat.tar',
      value: `OS_${OsVersion}AMI/ES2/Alpha/metricsdb.concat.tar`,
    },
    {
      label: 'opendistro_security_audit_service.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/opendistro_security_audit_service.log`,
    },
    {
      label: 'pa.agent.stats.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/pa.agent.stats.log`,
    },
    {
      label: 'pa.plugin.stats.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/pa.plugin.stats.log`,
    },
    {
      label: 'reactionwheel_application_metrics.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/reactionwheel_application_metrics.log`,
    },
    { label: 'service_log', value: `OS_${OsVersion}AMI/ES2/Alpha/service_log` },
    {
      label: 'supervisord.es.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/supervisord.es.log`,
    },
    {
      label: 'supervisord.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/supervisord.log`,
    },
    {
      label: 'supervisord.pa.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/supervisord.pa.log`,
    },
    {
      label: 'read-node-discovery-file.general.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/read-node-discovery-file.general.log`,
    },
    {
      label: 'write-node-discovery-file.general.log',
      value: `OS_${OsVersion}AMI/ES2/Alpha/write-node-discovery-file.general.log`,
    },
  ];
  logGroupOptions
    .find((x) => x.label === 'ElasticSearch')
    .options.push(...esLogGroups);
};

const supportedElasticSearchOSVersions = ['1_0', '1_1', '1_2', '1_3', '2_3', '2_5', '2_7', '2_9', '2_11', '2_13', '2_15', '2_17'];

for (const osVersion of supportedElasticSearchOSVersions) {
  addElasticSearchOSVersionLogGroups(osVersion);
}

// export const logGroupOptions = [
//   { label:"CloudConfigService/Gamma/JlbRelay", value:"CloudConfigService/Gamma/JlbRelay"},
//   { label:"CloudConfigService/Gamma/application.log", value:"CloudConfigService/Gamma/application.log"},
//   { label:"CloudConfigService/Gamma/auditlog.log", value:"CloudConfigService/Gamma/auditlog.log"},
//   { label:"CloudConfigService/Gamma/daemon.log", value:"CloudConfigService/Gamma/daemon.log"},
//   { label:"CloudConfigService/Gamma/fips_JlbRelay", value:"CloudConfigService/Gamma/fips_JlbRelay"},
//   { label:"CloudConfigService/Gamma/fips_relay_service_log", value:"CloudConfigService/Gamma/fips_relay_service_log"},
//   { label:"CloudConfigService/Gamma/log-remover.log", value:"CloudConfigService/Gamma/log-remover.log"},
//   { label:"CloudConfigService/Gamma/processmanager", value:"CloudConfigService/Gamma/processmanager"},
//   { label:"CloudConfigService/Gamma/relay_service_log", value:"CloudConfigService/Gamma/relay_service_log"},
//   { label:"CloudConfigService/Gamma/requests.log", value:"CloudConfigService/Gamma/requests.log"},
//   { label:"CloudConfigService/Gamma/service_log", value:"CloudConfigService/Gamma/service_log"},
//   { label:"CloudConfigService/Gamma/throttle_log", value:"CloudConfigService/Gamma/throttle_log"},
//   { label:"CloudConfigService/Gamma/website-log-pusher.log", value:"CloudConfigService/Gamma/website-log-pusher.log"},
//   { label:"CloudConfigService/Gamma/wire.log", value:"CloudConfigService/Gamma/wire.log"},
//   { label:"CloudConfigService/OneBox/Prod/JlbRelay", value:"CloudConfigService/OneBox/Prod/JlbRelay"},
//   { label:"CloudConfigService/OneBox/Prod/application.log", value:"CloudConfigService/OneBox/Prod/application.log"},
//   { label:"CloudConfigService/OneBox/Prod/auditlog.log", value:"CloudConfigService/OneBox/Prod/auditlog.log"},
//   { label:"CloudConfigService/OneBox/Prod/daemon.log", value:"CloudConfigService/OneBox/Prod/daemon.log"},
//   { label:"CloudConfigService/OneBox/Prod/fips_JlbRelay", value:"CloudConfigService/OneBox/Prod/fips_JlbRelay"},
//   { label:"CloudConfigService/OneBox/Prod/fips_relay_service_log", value:"CloudConfigService/OneBox/Prod/fips_relay_service_log"},
//   { label:"CloudConfigService/OneBox/Prod/log-remover.log", value:"CloudConfigService/OneBox/Prod/log-remover.log"},
//   { label:"CloudConfigService/OneBox/Prod/processmanager", value:"CloudConfigService/OneBox/Prod/processmanager"},
//   { label:"CloudConfigService/OneBox/Prod/relay_service_log", value:"CloudConfigService/OneBox/Prod/relay_service_log"},
//   { label:"CloudConfigService/OneBox/Prod/requests.log", value:"CloudConfigService/OneBox/Prod/requests.log"},
//   { label:"CloudConfigService/OneBox/Prod/service_log", value:"CloudConfigService/OneBox/Prod/service_log"},
//   { label:"CloudConfigService/OneBox/Prod/throttle_log", value:"CloudConfigService/OneBox/Prod/throttle_log"},
//   { label:"CloudConfigService/OneBox/Prod/website-log-pusher.log", value:"CloudConfigService/OneBox/Prod/website-log-pusher.log"},
//   { label:"CloudConfigService/OneBox/Prod/wire.log", value:"CloudConfigService/OneBox/Prod/wire.log"},
//   { label:"CloudConfigService/Prod/JlbRelay", value:"CloudConfigService/Prod/JlbRelay"},
//   { label:"CloudConfigService/Prod/application.log", value:"CloudConfigService/Prod/application.log"},
//   { label:"CloudConfigService/Prod/auditlog.log", value:"CloudConfigService/Prod/auditlog.log"},
//   { label:"CloudConfigService/Prod/aws-requests.general", value:"CloudConfigService/Prod/aws-requests.general"},
//   { label:"CloudConfigService/Prod/daemon.log", value:"CloudConfigService/Prod/daemon.log"},
//   { label:"CloudConfigService/Prod/fips_JlbRelay", value:"CloudConfigService/Prod/fips_JlbRelay"},
//   { label:"CloudConfigService/Prod/fips_relay_service_log", value:"CloudConfigService/Prod/fips_relay_service_log"},
//   { label:"CloudConfigService/Prod/log-remover.log", value:"CloudConfigService/Prod/log-remover.log"},
//   { label:"CloudConfigService/Prod/processmanager", value:"CloudConfigService/Prod/processmanager"},
//   { label:"CloudConfigService/Prod/relay_service_log", value:"CloudConfigService/Prod/relay_service_log"},
//   { label:"CloudConfigService/Prod/requests.log", value:"CloudConfigService/Prod/requests.log"},
//   { label:"CloudConfigService/Prod/service_log", value:"CloudConfigService/Prod/service_log"},
//   { label:"CloudConfigService/Prod/throttle_log", value:"CloudConfigService/Prod/throttle_log"},
//   { label:"CloudConfigService/Prod/website-log-pusher.log", value:"CloudConfigService/Prod/website-log-pusher.log"},
//   { label:"CloudConfigService/Prod/wire.log", value:"CloudConfigService/Prod/wire.log"},
//   { label:"ESAMI/ES2/Alpha/auth.general.log", value:"ESAMI/ES2/Alpha/auth.general.log"},
//   { label:"ESAMI/ES2/Alpha/auth.metrics.log", value:"ESAMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ESAMI/ES2/Alpha/check-for-hung-es.general.log", value:"ESAMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ESAMI/ES2/Alpha/elasticsearch.log", value:"ESAMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ESAMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log", value:"ESAMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log"},
//   { label:"ESAMI/ES2/Alpha/elasticsearch_index_search_slowlog.log", value:"ESAMI/ES2/Alpha/elasticsearch_index_search_slowlog.log"},
//   { label:"ESAMI/ES2/Alpha/es.metrics.log", value:"ESAMI/ES2/Alpha/es.metrics.log"},
//   { label:"ESAMI/ES2/Alpha/jcmd_class_histogram.log", value:"ESAMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ESAMI/ES2/Alpha/log-remover.log", value:"ESAMI/ES2/Alpha/log-remover.log"},
//   { label:"ESAMI/ES2/Alpha/log-rotator.log", value:"ESAMI/ES2/Alpha/log-rotator.log"},
//   { label:"ESAMI/ES2/Alpha/log_truncate.log", value:"ESAMI/ES2/Alpha/log_truncate.log"},
//   { label:"ESAMI/ES2/Alpha/metrics-db-files-concater.log", value:"ESAMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ESAMI/ES2/Alpha/security.saml.log", value:"ESAMI/ES2/Alpha/security.saml.log"},
//   { label:"ESAMI/ES2/Alpha/service_log", value:"ESAMI/ES2/Alpha/service_log"},
//   { label:"ESAMI/ES2/Alpha/supervisord.es.log", value:"ESAMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ESAMI/ES2/Alpha/supervisord.log", value:"ESAMI/ES2/Alpha/supervisord.log"},
//   { label:"ESAMI/ES2/Alpha/supervisord.pa.log", value:"ESAMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ESAMI/ES2/Alpha/website-log-pusher.log", value:"ESAMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/auth.general.log", value:"ES_2_3AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/auth.metrics.log", value:"ES_2_3AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_2_3AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/elasticsearch.log", value:"ES_2_3AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log", value:"ES_2_3AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log", value:"ES_2_3AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/es.metrics.log", value:"ES_2_3AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_2_3AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/log-remover.log", value:"ES_2_3AMI/ES2/Alpha/log-remover.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/log-rotator.log", value:"ES_2_3AMI/ES2/Alpha/log-rotator.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/log_truncate.log", value:"ES_2_3AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_2_3AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/service_log", value:"ES_2_3AMI/ES2/Alpha/service_log"},
//   { label:"ES_2_3AMI/ES2/Alpha/supervisord.es.log", value:"ES_2_3AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/supervisord.log", value:"ES_2_3AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/supervisord.pa.log", value:"ES_2_3AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_2_3AMI/ES2/Alpha/website-log-pusher.log", value:"ES_2_3AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/auth.general.log", value:"ES_5_1AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/auth.metrics.log", value:"ES_5_1AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_5_1AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/elasticsearch.log", value:"ES_5_1AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log", value:"ES_5_1AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log", value:"ES_5_1AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/es.metrics.log", value:"ES_5_1AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/es_deprecation.log", value:"ES_5_1AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_5_1AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/log-remover.log", value:"ES_5_1AMI/ES2/Alpha/log-remover.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/log-rotator.log", value:"ES_5_1AMI/ES2/Alpha/log-rotator.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/log_truncate.log", value:"ES_5_1AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_5_1AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/service_log", value:"ES_5_1AMI/ES2/Alpha/service_log"},
//   { label:"ES_5_1AMI/ES2/Alpha/supervisord.es.log", value:"ES_5_1AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/supervisord.log", value:"ES_5_1AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/supervisord.pa.log", value:"ES_5_1AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_5_1AMI/ES2/Alpha/website-log-pusher.log", value:"ES_5_1AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/auth.general.log", value:"ES_5_3AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/auth.metrics.log", value:"ES_5_3AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_5_3AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/elasticsearch.log", value:"ES_5_3AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log", value:"ES_5_3AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log", value:"ES_5_3AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/es.metrics.log", value:"ES_5_3AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/es_deprecation.log", value:"ES_5_3AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_5_3AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/log-remover.log", value:"ES_5_3AMI/ES2/Alpha/log-remover.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/log-rotator.log", value:"ES_5_3AMI/ES2/Alpha/log-rotator.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/log_truncate.log", value:"ES_5_3AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_5_3AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/service_log", value:"ES_5_3AMI/ES2/Alpha/service_log"},
//   { label:"ES_5_3AMI/ES2/Alpha/supervisord.es.log", value:"ES_5_3AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/supervisord.log", value:"ES_5_3AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/supervisord.pa.log", value:"ES_5_3AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_5_3AMI/ES2/Alpha/website-log-pusher.log", value:"ES_5_3AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/auth.general.log", value:"ES_5_5AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/auth.metrics.log", value:"ES_5_5AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_5_5AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/elasticsearch.log", value:"ES_5_5AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log", value:"ES_5_5AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log", value:"ES_5_5AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/es.metrics.log", value:"ES_5_5AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/es_deprecation.log", value:"ES_5_5AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_5_5AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/log-remover.log", value:"ES_5_5AMI/ES2/Alpha/log-remover.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/log-rotator.log", value:"ES_5_5AMI/ES2/Alpha/log-rotator.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/log_truncate.log", value:"ES_5_5AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_5_5AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/service_log", value:"ES_5_5AMI/ES2/Alpha/service_log"},
//   { label:"ES_5_5AMI/ES2/Alpha/supervisord.es.log", value:"ES_5_5AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/supervisord.log", value:"ES_5_5AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/supervisord.pa.log", value:"ES_5_5AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_5_5AMI/ES2/Alpha/website-log-pusher.log", value:"ES_5_5AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/auth.general.log", value:"ES_5_6AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/auth.metrics.log", value:"ES_5_6AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_5_6AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/elasticsearch.log", value:"ES_5_6AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log", value:"ES_5_6AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log", value:"ES_5_6AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/es.metrics.log", value:"ES_5_6AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/es_deprecation.log", value:"ES_5_6AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_5_6AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/log-remover.log", value:"ES_5_6AMI/ES2/Alpha/log-remover.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/log-rotator.log", value:"ES_5_6AMI/ES2/Alpha/log-rotator.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/log_truncate.log", value:"ES_5_6AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_5_6AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/service_log", value:"ES_5_6AMI/ES2/Alpha/service_log"},
//   { label:"ES_5_6AMI/ES2/Alpha/supervisord.es.log", value:"ES_5_6AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/supervisord.log", value:"ES_5_6AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/supervisord.pa.log", value:"ES_5_6AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_5_6AMI/ES2/Alpha/website-log-pusher.log", value:"ES_5_6AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_6_0AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/auth.general.log", value:"ES_6_0AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/auth.metrics.log", value:"ES_6_0AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/cert-reload.general.log", value:"ES_6_0AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_6_0AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/elasticsearch.log", value:"ES_6_0AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log", value:"ES_6_0AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log", value:"ES_6_0AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/es.metrics.log", value:"ES_6_0AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/es_deprecation.log", value:"ES_6_0AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_6_0AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/log-remover.log", value:"ES_6_0AMI/ES2/Alpha/log-remover.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/log-rotator.log", value:"ES_6_0AMI/ES2/Alpha/log-rotator.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/log_truncate.log", value:"ES_6_0AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_6_0AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_6_0AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_6_0AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_6_0AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_6_0AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/service_log", value:"ES_6_0AMI/ES2/Alpha/service_log"},
//   { label:"ES_6_0AMI/ES2/Alpha/supervisord.es.log", value:"ES_6_0AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/supervisord.log", value:"ES_6_0AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/supervisord.pa.log", value:"ES_6_0AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_6_0AMI/ES2/Alpha/website-log-pusher.log", value:"ES_6_0AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_6_2AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/auth.general.log", value:"ES_6_2AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/auth.metrics.log", value:"ES_6_2AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/cert-reload.general.log", value:"ES_6_2AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_6_2AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/elasticsearch.log", value:"ES_6_2AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log", value:"ES_6_2AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log", value:"ES_6_2AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/es.metrics.log", value:"ES_6_2AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/es_deprecation.log", value:"ES_6_2AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_6_2AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/log-remover.log", value:"ES_6_2AMI/ES2/Alpha/log-remover.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/log-rotator.log", value:"ES_6_2AMI/ES2/Alpha/log-rotator.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/log_truncate.log", value:"ES_6_2AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_6_2AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_6_2AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_6_2AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_6_2AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_6_2AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/service_log", value:"ES_6_2AMI/ES2/Alpha/service_log"},
//   { label:"ES_6_2AMI/ES2/Alpha/supervisord.es.log", value:"ES_6_2AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/supervisord.log", value:"ES_6_2AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/supervisord.pa.log", value:"ES_6_2AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_6_2AMI/ES2/Alpha/website-log-pusher.log", value:"ES_6_2AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_6_3AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/ReactionWheel.log", value:"ES_6_3AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/auth.general.log", value:"ES_6_3AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/auth.metrics.log", value:"ES_6_3AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/cert-reload.general.log", value:"ES_6_3AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_6_3AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/elasticsearch.log", value:"ES_6_3AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log", value:"ES_6_3AMI/ES2/Alpha/elasticsearch_index_indexing_slowlog.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log", value:"ES_6_3AMI/ES2/Alpha/elasticsearch_index_search_slowlog.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/es.metrics.log", value:"ES_6_3AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/es_deprecation.log", value:"ES_6_3AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_6_3AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/log-remover.log", value:"ES_6_3AMI/ES2/Alpha/log-remover.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/log-rotator.log", value:"ES_6_3AMI/ES2/Alpha/log-rotator.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/log_truncate.log", value:"ES_6_3AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_6_3AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_6_3AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_6_3AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_6_3AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_6_3AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"ES_6_3AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/service_log", value:"ES_6_3AMI/ES2/Alpha/service_log"},
//   { label:"ES_6_3AMI/ES2/Alpha/supervisord.es.log", value:"ES_6_3AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/supervisord.log", value:"ES_6_3AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/supervisord.pa.log", value:"ES_6_3AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_6_3AMI/ES2/Alpha/website-log-pusher.log", value:"ES_6_3AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/auth.general.log", value:"ES_6_4AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/auth.metrics.log", value:"ES_6_4AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/cert-reload.general.log", value:"ES_6_4AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_6_4AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/elasticsearch.log", value:"ES_6_4AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/es.metrics.log", value:"ES_6_4AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/es_deprecation.log", value:"ES_6_4AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_6_4AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/log_truncate.log", value:"ES_6_4AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_6_4AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_6_4AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_6_4AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_6_4AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_6_4AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/service_log", value:"ES_6_4AMI/ES2/Alpha/service_log"},
//   { label:"ES_6_4AMI/ES2/Alpha/supervisord.es.log", value:"ES_6_4AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/supervisord.log", value:"ES_6_4AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_6_4AMI/ES2/Alpha/supervisord.pa.log", value:"ES_6_4AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_6_5AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/auth.general.log", value:"ES_6_5AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/auth.metrics.log", value:"ES_6_5AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/cert-reload.general.log", value:"ES_6_5AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_6_5AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/elasticsearch.log", value:"ES_6_5AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/es.metrics.log", value:"ES_6_5AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/es_deprecation.log", value:"ES_6_5AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_6_5AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/log_truncate.log", value:"ES_6_5AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_6_5AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_6_5AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_6_5AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_6_5AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_6_5AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/service_log", value:"ES_6_5AMI/ES2/Alpha/service_log"},
//   { label:"ES_6_5AMI/ES2/Alpha/supervisord.es.log", value:"ES_6_5AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/supervisord.log", value:"ES_6_5AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_6_5AMI/ES2/Alpha/supervisord.pa.log", value:"ES_6_5AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_6_7AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/ReactionWheel.log", value:"ES_6_7AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/auth.general.log", value:"ES_6_7AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/auth.metrics.log", value:"ES_6_7AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/aws-requests.general", value:"ES_6_7AMI/ES2/Alpha/aws-requests.general"},
//   { label:"ES_6_7AMI/ES2/Alpha/cert-reload.general.log", value:"ES_6_7AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_6_7AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/elasticsearch.log", value:"ES_6_7AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/es.metrics.log", value:"ES_6_7AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/es_deprecation.log", value:"ES_6_7AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_6_7AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/log_truncate.log", value:"ES_6_7AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_6_7AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_6_7AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_6_7AMI/ES2/Alpha/opendistro_security_audit.log", value:"ES_6_7AMI/ES2/Alpha/opendistro_security_audit.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/opendistro_security_audit_service.log", value:"ES_6_7AMI/ES2/Alpha/opendistro_security_audit_service.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_6_7AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_6_7AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"ES_6_7AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/security.saml.log", value:"ES_6_7AMI/ES2/Alpha/security.saml.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/service_log", value:"ES_6_7AMI/ES2/Alpha/service_log"},
//   { label:"ES_6_7AMI/ES2/Alpha/supervisord.es.log", value:"ES_6_7AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/supervisord.log", value:"ES_6_7AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_6_7AMI/ES2/Alpha/supervisord.pa.log", value:"ES_6_7AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_6_8AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/ReactionWheel.log", value:"ES_6_8AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/auth.general.log", value:"ES_6_8AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/auth.metrics.log", value:"ES_6_8AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/aws-requests.general", value:"ES_6_8AMI/ES2/Alpha/aws-requests.general"},
//   { label:"ES_6_8AMI/ES2/Alpha/cert-reload.general.log", value:"ES_6_8AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_6_8AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/elasticsearch.log", value:"ES_6_8AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log", value:"ES_6_8AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/es.metrics.log", value:"ES_6_8AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/es_deprecation.log", value:"ES_6_8AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_6_8AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/log_truncate.log", value:"ES_6_8AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_6_8AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_6_8AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_6_8AMI/ES2/Alpha/opendistro_security_audit.log", value:"ES_6_8AMI/ES2/Alpha/opendistro_security_audit.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/opendistro_security_audit_service.log", value:"ES_6_8AMI/ES2/Alpha/opendistro_security_audit_service.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_6_8AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_6_8AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"ES_6_8AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/security.saml.log", value:"ES_6_8AMI/ES2/Alpha/security.saml.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/service_log", value:"ES_6_8AMI/ES2/Alpha/service_log"},
//   { label:"ES_6_8AMI/ES2/Alpha/supervisord.es.log", value:"ES_6_8AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/supervisord.log", value:"ES_6_8AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_6_8AMI/ES2/Alpha/supervisord.pa.log", value:"ES_6_8AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_7_10AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/ReactionWheel.log", value:"ES_7_10AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/auth.general.log", value:"ES_7_10AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/auth.metrics.log", value:"ES_7_10AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/aws-requests.general", value:"ES_7_10AMI/ES2/Alpha/aws-requests.general"},
//   { label:"ES_7_10AMI/ES2/Alpha/cert-reload.general.log", value:"ES_7_10AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_7_10AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/elasticsearch.log", value:"ES_7_10AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log", value:"ES_7_10AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/es.metrics.log", value:"ES_7_10AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/es_deprecation.log", value:"ES_7_10AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_7_10AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/log_truncate.log", value:"ES_7_10AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_7_10AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_7_10AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_7_10AMI/ES2/Alpha/opendistro_security_audit_service.log", value:"ES_7_10AMI/ES2/Alpha/opendistro_security_audit_service.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_7_10AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_7_10AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"ES_7_10AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/service_log", value:"ES_7_10AMI/ES2/Alpha/service_log"},
//   { label:"ES_7_10AMI/ES2/Alpha/supervisord.es.log", value:"ES_7_10AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/supervisord.log", value:"ES_7_10AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_7_10AMI/ES2/Alpha/supervisord.pa.log", value:"ES_7_10AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_7_1AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/ReactionWheel.log", value:"ES_7_1AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/auth.general.log", value:"ES_7_1AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/auth.metrics.log", value:"ES_7_1AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/aws-requests.general", value:"ES_7_1AMI/ES2/Alpha/aws-requests.general"},
//   { label:"ES_7_1AMI/ES2/Alpha/cert-reload.general.log", value:"ES_7_1AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_7_1AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/elasticsearch.log", value:"ES_7_1AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log", value:"ES_7_1AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/es.metrics.log", value:"ES_7_1AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/es_deprecation.log", value:"ES_7_1AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_7_1AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/log_truncate.log", value:"ES_7_1AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_7_1AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_7_1AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_7_1AMI/ES2/Alpha/opendistro_security_audit.log", value:"ES_7_1AMI/ES2/Alpha/opendistro_security_audit.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/opendistro_security_audit_service.log", value:"ES_7_1AMI/ES2/Alpha/opendistro_security_audit_service.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_7_1AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_7_1AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"ES_7_1AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/security.saml.log", value:"ES_7_1AMI/ES2/Alpha/security.saml.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/service_log", value:"ES_7_1AMI/ES2/Alpha/service_log"},
//   { label:"ES_7_1AMI/ES2/Alpha/supervisord.es.log", value:"ES_7_1AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/supervisord.log", value:"ES_7_1AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_7_1AMI/ES2/Alpha/supervisord.pa.log", value:"ES_7_1AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_7_4AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/ReactionWheel.log", value:"ES_7_4AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/auth.general.log", value:"ES_7_4AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/auth.metrics.log", value:"ES_7_4AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/aws-requests.general", value:"ES_7_4AMI/ES2/Alpha/aws-requests.general"},
//   { label:"ES_7_4AMI/ES2/Alpha/cert-reload.general.log", value:"ES_7_4AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_7_4AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/elasticsearch.log", value:"ES_7_4AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log", value:"ES_7_4AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/es.metrics.log", value:"ES_7_4AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/es_deprecation.log", value:"ES_7_4AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_7_4AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/log_truncate.log", value:"ES_7_4AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_7_4AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_7_4AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_7_4AMI/ES2/Alpha/opendistro_security_audit.log", value:"ES_7_4AMI/ES2/Alpha/opendistro_security_audit.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/opendistro_security_audit_service.log", value:"ES_7_4AMI/ES2/Alpha/opendistro_security_audit_service.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_7_4AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_7_4AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"ES_7_4AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/security.saml.log", value:"ES_7_4AMI/ES2/Alpha/security.saml.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/service_log", value:"ES_7_4AMI/ES2/Alpha/service_log"},
//   { label:"ES_7_4AMI/ES2/Alpha/supervisord.es.log", value:"ES_7_4AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/supervisord.log", value:"ES_7_4AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_7_4AMI/ES2/Alpha/supervisord.pa.log", value:"ES_7_4AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_7_7AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/ReactionWheel.log", value:"ES_7_7AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/auth.general.log", value:"ES_7_7AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/auth.metrics.log", value:"ES_7_7AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/aws-requests.general", value:"ES_7_7AMI/ES2/Alpha/aws-requests.general"},
//   { label:"ES_7_7AMI/ES2/Alpha/cert-reload.general.log", value:"ES_7_7AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_7_7AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/elasticsearch.log", value:"ES_7_7AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log", value:"ES_7_7AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/es.metrics.log", value:"ES_7_7AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/es_deprecation.log", value:"ES_7_7AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_7_7AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/log_truncate.log", value:"ES_7_7AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_7_7AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_7_7AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_7_7AMI/ES2/Alpha/opendistro_security_audit.log", value:"ES_7_7AMI/ES2/Alpha/opendistro_security_audit.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/opendistro_security_audit_service.log", value:"ES_7_7AMI/ES2/Alpha/opendistro_security_audit_service.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_7_7AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_7_7AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"ES_7_7AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/security.saml.log", value:"ES_7_7AMI/ES2/Alpha/security.saml.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/service_log", value:"ES_7_7AMI/ES2/Alpha/service_log"},
//   { label:"ES_7_7AMI/ES2/Alpha/supervisord.es.log", value:"ES_7_7AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/supervisord.log", value:"ES_7_7AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_7_7AMI/ES2/Alpha/supervisord.pa.log", value:"ES_7_7AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_7_8AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/ReactionWheel.log", value:"ES_7_8AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/auth.general.log", value:"ES_7_8AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/auth.metrics.log", value:"ES_7_8AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/aws-requests.general", value:"ES_7_8AMI/ES2/Alpha/aws-requests.general"},
//   { label:"ES_7_8AMI/ES2/Alpha/cert-reload.general.log", value:"ES_7_8AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_7_8AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/elasticsearch.log", value:"ES_7_8AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log", value:"ES_7_8AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/es.metrics.log", value:"ES_7_8AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/es_deprecation.log", value:"ES_7_8AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_7_8AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/log_truncate.log", value:"ES_7_8AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_7_8AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_7_8AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_7_8AMI/ES2/Alpha/opendistro_security_audit_service.log", value:"ES_7_8AMI/ES2/Alpha/opendistro_security_audit_service.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_7_8AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_7_8AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"ES_7_8AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/security.saml.log", value:"ES_7_8AMI/ES2/Alpha/security.saml.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/service_log", value:"ES_7_8AMI/ES2/Alpha/service_log"},
//   { label:"ES_7_8AMI/ES2/Alpha/supervisord.es.log", value:"ES_7_8AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/supervisord.log", value:"ES_7_8AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_7_8AMI/ES2/Alpha/supervisord.pa.log", value:"ES_7_8AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"ES_7_9AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/ReactionWheel.log", value:"ES_7_9AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/auth.general.log", value:"ES_7_9AMI/ES2/Alpha/auth.general.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/auth.metrics.log", value:"ES_7_9AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/aws-requests.general", value:"ES_7_9AMI/ES2/Alpha/aws-requests.general"},
//   { label:"ES_7_9AMI/ES2/Alpha/cert-reload.general.log", value:"ES_7_9AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/check-for-hung-es.general.log", value:"ES_7_9AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/elasticsearch.log", value:"ES_7_9AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log", value:"ES_7_9AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/es.metrics.log", value:"ES_7_9AMI/ES2/Alpha/es.metrics.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/es_deprecation.log", value:"ES_7_9AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/jcmd_class_histogram.log", value:"ES_7_9AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/log_truncate.log", value:"ES_7_9AMI/ES2/Alpha/log_truncate.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/metrics-db-files-concater.log", value:"ES_7_9AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/metricsdb.concat.tar", value:"ES_7_9AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"ES_7_9AMI/ES2/Alpha/opendistro_security_audit_service.log", value:"ES_7_9AMI/ES2/Alpha/opendistro_security_audit_service.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/pa.agent.stats.log", value:"ES_7_9AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/pa.plugin.stats.log", value:"ES_7_9AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"ES_7_9AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/security.saml.log", value:"ES_7_9AMI/ES2/Alpha/security.saml.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/service_log", value:"ES_7_9AMI/ES2/Alpha/service_log"},
//   { label:"ES_7_9AMI/ES2/Alpha/supervisord.es.log", value:"ES_7_9AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/supervisord.log", value:"ES_7_9AMI/ES2/Alpha/supervisord.log"},
//   { label:"ES_7_9AMI/ES2/Alpha/supervisord.pa.log", value:"ES_7_9AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"InstanceControllerAMI/ES2/Alpha/access", value:"InstanceControllerAMI/ES2/Alpha/access"},
//   { label:"InstanceControllerAMI/ES2/Alpha/aws-requests.general", value:"InstanceControllerAMI/ES2/Alpha/aws-requests.general"},
//   { label:"InstanceControllerAMI/ES2/Alpha/errors", value:"InstanceControllerAMI/ES2/Alpha/errors"},
//   { label:"InstanceControllerAMI/ES2/Alpha/healthcheck.general", value:"InstanceControllerAMI/ES2/Alpha/healthcheck.general"},
//   { label:"InstanceControllerAMI/ES2/Alpha/log-remover.log", value:"InstanceControllerAMI/ES2/Alpha/log-remover.log"},
//   { label:"InstanceControllerAMI/ES2/Alpha/processmanager", value:"InstanceControllerAMI/ES2/Alpha/processmanager"},
//   { label:"InstanceControllerAMI/ES2/Alpha/service_log", value:"InstanceControllerAMI/ES2/Alpha/service_log"},
//   { label:"InstanceControllerAMI/ES2/Alpha/website-log-pusher.log", value:"InstanceControllerAMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"Kibana_5_1AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_5_1AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_5_1AMI/ES2/Alpha/kibana.log", value:"Kibana_5_1AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_5_1AMI/ES2/Alpha/log-remover.log", value:"Kibana_5_1AMI/ES2/Alpha/log-remover.log"},
//   { label:"Kibana_5_1AMI/ES2/Alpha/service_log", value:"Kibana_5_1AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_5_1AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_5_1AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_5_1AMI/ES2/Alpha/supervisord.log", value:"Kibana_5_1AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_5_1AMI/ES2/Alpha/website-log-pusher.log", value:"Kibana_5_1AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"Kibana_5_3AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_5_3AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_5_3AMI/ES2/Alpha/kibana.log", value:"Kibana_5_3AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_5_3AMI/ES2/Alpha/log-remover.log", value:"Kibana_5_3AMI/ES2/Alpha/log-remover.log"},
//   { label:"Kibana_5_3AMI/ES2/Alpha/service_log", value:"Kibana_5_3AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_5_3AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_5_3AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_5_3AMI/ES2/Alpha/supervisord.log", value:"Kibana_5_3AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_5_3AMI/ES2/Alpha/website-log-pusher.log", value:"Kibana_5_3AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"Kibana_5_5AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_5_5AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_5_5AMI/ES2/Alpha/kibana.log", value:"Kibana_5_5AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_5_5AMI/ES2/Alpha/log-remover.log", value:"Kibana_5_5AMI/ES2/Alpha/log-remover.log"},
//   { label:"Kibana_5_5AMI/ES2/Alpha/service_log", value:"Kibana_5_5AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_5_5AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_5_5AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_5_5AMI/ES2/Alpha/supervisord.log", value:"Kibana_5_5AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_5_5AMI/ES2/Alpha/website-log-pusher.log", value:"Kibana_5_5AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"Kibana_5_6AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_5_6AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_5_6AMI/ES2/Alpha/kibana.log", value:"Kibana_5_6AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_5_6AMI/ES2/Alpha/log-remover.log", value:"Kibana_5_6AMI/ES2/Alpha/log-remover.log"},
//   { label:"Kibana_5_6AMI/ES2/Alpha/service_log", value:"Kibana_5_6AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_5_6AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_5_6AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_5_6AMI/ES2/Alpha/supervisord.log", value:"Kibana_5_6AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_5_6AMI/ES2/Alpha/website-log-pusher.log", value:"Kibana_5_6AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"Kibana_6_0AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_6_0AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_6_0AMI/ES2/Alpha/kibana.log", value:"Kibana_6_0AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_6_0AMI/ES2/Alpha/log-remover.log", value:"Kibana_6_0AMI/ES2/Alpha/log-remover.log"},
//   { label:"Kibana_6_0AMI/ES2/Alpha/service_log", value:"Kibana_6_0AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_6_0AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_6_0AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_6_0AMI/ES2/Alpha/supervisord.log", value:"Kibana_6_0AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_6_0AMI/ES2/Alpha/website-log-pusher.log", value:"Kibana_6_0AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"Kibana_6_2AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_6_2AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_6_2AMI/ES2/Alpha/kibana.log", value:"Kibana_6_2AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_6_2AMI/ES2/Alpha/log-remover.log", value:"Kibana_6_2AMI/ES2/Alpha/log-remover.log"},
//   { label:"Kibana_6_2AMI/ES2/Alpha/service_log", value:"Kibana_6_2AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_6_2AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_6_2AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_6_2AMI/ES2/Alpha/supervisord.log", value:"Kibana_6_2AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_6_2AMI/ES2/Alpha/website-log-pusher.log", value:"Kibana_6_2AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"Kibana_6_3AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_6_3AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_6_3AMI/ES2/Alpha/kibana.log", value:"Kibana_6_3AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_6_3AMI/ES2/Alpha/log-remover.log", value:"Kibana_6_3AMI/ES2/Alpha/log-remover.log"},
//   { label:"Kibana_6_3AMI/ES2/Alpha/service_log", value:"Kibana_6_3AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_6_3AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_6_3AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_6_3AMI/ES2/Alpha/supervisord.log", value:"Kibana_6_3AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_6_3AMI/ES2/Alpha/website-log-pusher.log", value:"Kibana_6_3AMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"Kibana_6_4AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_6_4AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_6_4AMI/ES2/Alpha/kibana.log", value:"Kibana_6_4AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_6_4AMI/ES2/Alpha/service_log", value:"Kibana_6_4AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_6_4AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_6_4AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_6_4AMI/ES2/Alpha/supervisord.log", value:"Kibana_6_4AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_6_5AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_6_5AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_6_5AMI/ES2/Alpha/kibana.log", value:"Kibana_6_5AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_6_5AMI/ES2/Alpha/service_log", value:"Kibana_6_5AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_6_5AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_6_5AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_6_5AMI/ES2/Alpha/supervisord.log", value:"Kibana_6_5AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_6_7AMI/ES2/Alpha/aws-requests.general", value:"Kibana_6_7AMI/ES2/Alpha/aws-requests.general"},
//   { label:"Kibana_6_7AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_6_7AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_6_7AMI/ES2/Alpha/kibana.log", value:"Kibana_6_7AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_6_7AMI/ES2/Alpha/service_log", value:"Kibana_6_7AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_6_7AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_6_7AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_6_7AMI/ES2/Alpha/supervisord.log", value:"Kibana_6_7AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_6_8AMI/ES2/Alpha/aws-requests.general", value:"Kibana_6_8AMI/ES2/Alpha/aws-requests.general"},
//   { label:"Kibana_6_8AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_6_8AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_6_8AMI/ES2/Alpha/kibana.log", value:"Kibana_6_8AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_6_8AMI/ES2/Alpha/service_log", value:"Kibana_6_8AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_6_8AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_6_8AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_6_8AMI/ES2/Alpha/supervisord.log", value:"Kibana_6_8AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_7_10AMI/ES2/Alpha/aws-requests.general", value:"Kibana_7_10AMI/ES2/Alpha/aws-requests.general"},
//   { label:"Kibana_7_10AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_7_10AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_7_10AMI/ES2/Alpha/kibana.log", value:"Kibana_7_10AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_7_10AMI/ES2/Alpha/service_log", value:"Kibana_7_10AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_7_10AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_7_10AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_7_10AMI/ES2/Alpha/supervisord.log", value:"Kibana_7_10AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_7_1AMI/ES2/Alpha/aws-requests.general", value:"Kibana_7_1AMI/ES2/Alpha/aws-requests.general"},
//   { label:"Kibana_7_1AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_7_1AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_7_1AMI/ES2/Alpha/kibana.log", value:"Kibana_7_1AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_7_1AMI/ES2/Alpha/service_log", value:"Kibana_7_1AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_7_1AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_7_1AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_7_1AMI/ES2/Alpha/supervisord.log", value:"Kibana_7_1AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_7_4AMI/ES2/Alpha/aws-requests.general", value:"Kibana_7_4AMI/ES2/Alpha/aws-requests.general"},
//   { label:"Kibana_7_4AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_7_4AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_7_4AMI/ES2/Alpha/kibana.log", value:"Kibana_7_4AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_7_4AMI/ES2/Alpha/service_log", value:"Kibana_7_4AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_7_4AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_7_4AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_7_4AMI/ES2/Alpha/supervisord.log", value:"Kibana_7_4AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_7_7AMI/ES2/Alpha/aws-requests.general", value:"Kibana_7_7AMI/ES2/Alpha/aws-requests.general"},
//   { label:"Kibana_7_7AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_7_7AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_7_7AMI/ES2/Alpha/kibana.log", value:"Kibana_7_7AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_7_7AMI/ES2/Alpha/service_log", value:"Kibana_7_7AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_7_7AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_7_7AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_7_7AMI/ES2/Alpha/supervisord.log", value:"Kibana_7_7AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_7_8AMI/ES2/Alpha/aws-requests.general", value:"Kibana_7_8AMI/ES2/Alpha/aws-requests.general"},
//   { label:"Kibana_7_8AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_7_8AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_7_8AMI/ES2/Alpha/kibana.log", value:"Kibana_7_8AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_7_8AMI/ES2/Alpha/service_log", value:"Kibana_7_8AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_7_8AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_7_8AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_7_8AMI/ES2/Alpha/supervisord.log", value:"Kibana_7_8AMI/ES2/Alpha/supervisord.log"},
//   { label:"Kibana_7_9AMI/ES2/Alpha/aws-requests.general", value:"Kibana_7_9AMI/ES2/Alpha/aws-requests.general"},
//   { label:"Kibana_7_9AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"Kibana_7_9AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"Kibana_7_9AMI/ES2/Alpha/kibana.log", value:"Kibana_7_9AMI/ES2/Alpha/kibana.log"},
//   { label:"Kibana_7_9AMI/ES2/Alpha/service_log", value:"Kibana_7_9AMI/ES2/Alpha/service_log"},
//   { label:"Kibana_7_9AMI/ES2/Alpha/supervisord.kibana.log", value:"Kibana_7_9AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"Kibana_7_9AMI/ES2/Alpha/supervisord.log", value:"Kibana_7_9AMI/ES2/Alpha/supervisord.log"},
//   { label:"LogPusherAMI/ES2/Alpha/a9-ec2-boot-start", value:"LogPusherAMI/ES2/Alpha/a9-ec2-boot-start"},
//   { label:"LogPusherAMI/ES2/Alpha/a9-ec2-boot-stop", value:"LogPusherAMI/ES2/Alpha/a9-ec2-boot-stop"},
//   { label:"LogPusherAMI/ES2/Alpha/chronicle.log", value:"LogPusherAMI/ES2/Alpha/chronicle.log"},
//   { label:"LogPusherAMI/ES2/Alpha/cw-log-pusher.general.log", value:"LogPusherAMI/ES2/Alpha/cw-log-pusher.general.log"},
//   { label:"LogPusherAMI/ES2/Alpha/cw.agent.log", value:"LogPusherAMI/ES2/Alpha/cw.agent.log"},
//   { label:"LogPusherAMI/ES2/Alpha/cwagent.metrics.log", value:"LogPusherAMI/ES2/Alpha/cwagent.metrics.log"},
//   { label:"LogPusherAMI/ES2/Alpha/log-remover.log", value:"LogPusherAMI/ES2/Alpha/log-remover.log"},
//   { label:"LogPusherAMI/ES2/Alpha/service_log", value:"LogPusherAMI/ES2/Alpha/service_log"},
//   { label:"LogPusherAMI/ES2/Alpha/website-log-pusher.log", value:"LogPusherAMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"OSD_1_0AMI/ES2/Alpha/aws-requests.general", value:"OSD_1_0AMI/ES2/Alpha/aws-requests.general"},
//   { label:"OSD_1_0AMI/ES2/Alpha/check-for-hung-kibana.general.log", value:"OSD_1_0AMI/ES2/Alpha/check-for-hung-kibana.general.log"},
//   { label:"OSD_1_0AMI/ES2/Alpha/kibana.log", value:"OSD_1_0AMI/ES2/Alpha/kibana.log"},
//   { label:"OSD_1_0AMI/ES2/Alpha/service_log", value:"OSD_1_0AMI/ES2/Alpha/service_log"},
//   { label:"OSD_1_0AMI/ES2/Alpha/supervisord.kibana.log", value:"OSD_1_0AMI/ES2/Alpha/supervisord.kibana.log"},
//   { label:"OSD_1_0AMI/ES2/Alpha/supervisord.log", value:"OSD_1_0AMI/ES2/Alpha/supervisord.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/PerformanceAnalyzer.log", value:"OS_1_0AMI/ES2/Alpha/PerformanceAnalyzer.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/ReactionWheel.log", value:"OS_1_0AMI/ES2/Alpha/ReactionWheel.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/auth.general.log", value:"OS_1_0AMI/ES2/Alpha/auth.general.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/auth.metrics.log", value:"OS_1_0AMI/ES2/Alpha/auth.metrics.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/aws-requests.general", value:"OS_1_0AMI/ES2/Alpha/aws-requests.general"},
//   { label:"OS_1_0AMI/ES2/Alpha/cert-reload.general.log", value:"OS_1_0AMI/ES2/Alpha/cert-reload.general.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/check-for-hung-es.general.log", value:"OS_1_0AMI/ES2/Alpha/check-for-hung-es.general.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/elasticsearch.log", value:"OS_1_0AMI/ES2/Alpha/elasticsearch.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log", value:"OS_1_0AMI/ES2/Alpha/elasticsearch_index_warm_slowlog.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/es.metrics.log", value:"OS_1_0AMI/ES2/Alpha/es.metrics.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/es_deprecation.log", value:"OS_1_0AMI/ES2/Alpha/es_deprecation.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/jcmd_class_histogram.log", value:"OS_1_0AMI/ES2/Alpha/jcmd_class_histogram.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/log_truncate.log", value:"OS_1_0AMI/ES2/Alpha/log_truncate.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/metrics-db-files-concater.log", value:"OS_1_0AMI/ES2/Alpha/metrics-db-files-concater.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/metricsdb.concat.tar", value:"OS_1_0AMI/ES2/Alpha/metricsdb.concat.tar"},
//   { label:"OS_1_0AMI/ES2/Alpha/opendistro_security_audit_service.log", value:"OS_1_0AMI/ES2/Alpha/opendistro_security_audit_service.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/pa.agent.stats.log", value:"OS_1_0AMI/ES2/Alpha/pa.agent.stats.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/pa.plugin.stats.log", value:"OS_1_0AMI/ES2/Alpha/pa.plugin.stats.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/reactionwheel_application_metrics.log", value:"OS_1_0AMI/ES2/Alpha/reactionwheel_application_metrics.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/service_log", value:"OS_1_0AMI/ES2/Alpha/service_log"},
//   { label:"OS_1_0AMI/ES2/Alpha/supervisord.es.log", value:"OS_1_0AMI/ES2/Alpha/supervisord.es.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/supervisord.log", value:"OS_1_0AMI/ES2/Alpha/supervisord.log"},
//   { label:"OS_1_0AMI/ES2/Alpha/supervisord.pa.log", value:"OS_1_0AMI/ES2/Alpha/supervisord.pa.log"},
//   { label:"ResourceManager/OneBox/Prod/activities.general.log", value:"ResourceManager/OneBox/Prod/activities.general.log"},
//   { label:"ResourceManager/OneBox/Prod/auditlog.log", value:"ResourceManager/OneBox/Prod/auditlog.log"},
//   { label:"ResourceManager/OneBox/Prod/aws-requests.general", value:"ResourceManager/OneBox/Prod/aws-requests.general"},
//   { label:"ResourceManager/OneBox/Prod/cw-events-queue-consumer.general", value:"ResourceManager/OneBox/Prod/cw-events-queue-consumer.general"},
//   { label:"ResourceManager/OneBox/Prod/domain-manager-activity-poller.general", value:"ResourceManager/OneBox/Prod/domain-manager-activity-poller.general"},
//   { label:"ResourceManager/OneBox/Prod/domain-manager-workflow-poller.general", value:"ResourceManager/OneBox/Prod/domain-manager-workflow-poller.general"},
//   { label:"ResourceManager/OneBox/Prod/domain-monitor-activity-poller.general", value:"ResourceManager/OneBox/Prod/domain-monitor-activity-poller.general"},
//   { label:"ResourceManager/OneBox/Prod/domain-monitor-workflow-poller.general", value:"ResourceManager/OneBox/Prod/domain-monitor-workflow-poller.general"},
//   { label:"ResourceManager/OneBox/Prod/log-remover.log", value:"ResourceManager/OneBox/Prod/log-remover.log"},
//   { label:"ResourceManager/OneBox/Prod/ops-commands", value:"ResourceManager/OneBox/Prod/ops-commands"},
//   { label:"ResourceManager/OneBox/Prod/processmanager", value:"ResourceManager/OneBox/Prod/processmanager"},
//   { label:"ResourceManager/OneBox/Prod/service_log", value:"ResourceManager/OneBox/Prod/service_log"},
//   { label:"ResourceManager/OneBox/Prod/software-cm-activity-poller.general", value:"ResourceManager/OneBox/Prod/software-cm-activity-poller.general"},
//   { label:"ResourceManager/OneBox/Prod/software-cm-workflow-poller.general", value:"ResourceManager/OneBox/Prod/software-cm-workflow-poller.general"},
//   { label:"ResourceManager/OneBox/Prod/website-log-pusher.log", value:"ResourceManager/OneBox/Prod/website-log-pusher.log"},
//   { label:"ResourceManager/Prod/activities.general.log", value:"ResourceManager/Prod/activities.general.log"},
//   { label:"ResourceManager/Prod/auditlog.log", value:"ResourceManager/Prod/auditlog.log"},
//   { label:"ResourceManager/Prod/aws-requests.general", value:"ResourceManager/Prod/aws-requests.general"},
//   { label:"ResourceManager/Prod/cw-events-queue-consumer.general", value:"ResourceManager/Prod/cw-events-queue-consumer.general"},
//   { label:"ResourceManager/Prod/domain-manager-activity-poller.general", value:"ResourceManager/Prod/domain-manager-activity-poller.general"},
//   { label:"ResourceManager/Prod/domain-manager-workflow-poller.general", value:"ResourceManager/Prod/domain-manager-workflow-poller.general"},
//   { label:"ResourceManager/Prod/domain-monitor-activity-poller.general", value:"ResourceManager/Prod/domain-monitor-activity-poller.general"},
//   { label:"ResourceManager/Prod/domain-monitor-workflow-poller.general", value:"ResourceManager/Prod/domain-monitor-workflow-poller.general"},
//   { label:"ResourceManager/Prod/log-remover.log", value:"ResourceManager/Prod/log-remover.log"},
//   { label:"ResourceManager/Prod/ops-commands", value:"ResourceManager/Prod/ops-commands"},
//   { label:"ResourceManager/Prod/processmanager", value:"ResourceManager/Prod/processmanager"},
//   { label:"ResourceManager/Prod/service_log", value:"ResourceManager/Prod/service_log"},
//   { label:"ResourceManager/Prod/software-cm-activity-poller.general", value:"ResourceManager/Prod/software-cm-activity-poller.general"},
//   { label:"ResourceManager/Prod/software-cm-workflow-poller.general", value:"ResourceManager/Prod/software-cm-workflow-poller.general"},
//   { label:"ResourceManager/Prod/website-log-pusher.log", value:"ResourceManager/Prod/website-log-pusher.log"},
//   { label:"SDPDAgentAMI/ES2/Alpha/sdpd_agent.log", value:"SDPDAgentAMI/ES2/Alpha/sdpd_agent.log"},
//   { label:"SDPDAgentAMI/ES2/Alpha/sdpd_metrics.log", value:"SDPDAgentAMI/ES2/Alpha/sdpd_metrics.log"},
//   { label:"SDPDAgentAMI/ES2/Alpha/service_log", value:"SDPDAgentAMI/ES2/Alpha/service_log"},
//   { label:"ServiceProxyAMI/ES2/Alpha/aws-requests.general", value:"ServiceProxyAMI/ES2/Alpha/aws-requests.general"},
//   { label:"ServiceProxyAMI/ES2/Alpha/haproxy.log", value:"ServiceProxyAMI/ES2/Alpha/haproxy.log"},
//   { label:"ServiceProxyAMI/ES2/Alpha/log-remover.log", value:"ServiceProxyAMI/ES2/Alpha/log-remover.log"},
//   { label:"ServiceProxyAMI/ES2/Alpha/log_truncate.log", value:"ServiceProxyAMI/ES2/Alpha/log_truncate.log"},
//   { label:"ServiceProxyAMI/ES2/Alpha/supervisord.log", value:"ServiceProxyAMI/ES2/Alpha/supervisord.log"},
//   { label:"ServiceProxyAMI/ES2/Alpha/supervisord.watchdog.log", value:"ServiceProxyAMI/ES2/Alpha/supervisord.watchdog.log"},
//   { label:"ServiceProxyAMI/ES2/Alpha/website-log-pusher.log", value:"ServiceProxyAMI/ES2/Alpha/website-log-pusher.log"},
//   { label:"SwiftConsole/Prod/access", value:"SwiftConsole/Prod/access"},
//   { label:"SwiftConsole/Prod/access-log", value:"SwiftConsole/Prod/access-log"},
//   { label:"SwiftConsole/Prod/catalina.out.log", value:"SwiftConsole/Prod/catalina.out.log"},
//   { label:"SwiftConsole/Prod/log-remover.log", value:"SwiftConsole/Prod/log-remover.log"},
//   { label:"SwiftConsole/Prod/processmanager", value:"SwiftConsole/Prod/processmanager"},
//   { label:"SwiftConsole/Prod/service_log", value:"SwiftConsole/Prod/service_log"},
//   { label:"SwiftConsole/Prod/website-log-pusher.log", value:"SwiftConsole/Prod/website-log-pusher.log"},
// ]

let d = new Date();
let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
const isDST = Math.max(jan, jul) !== d.getTimezoneOffset();
export const timezoneOptions = [
  {
    inputDisplay: 'Browser Timezone',
    label: 'Browser Timezone',
    value: 'Browser Timezone',
    code: new Date()
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')[2],
    offset: '',
    mins: new Date().getTimezoneOffset() * -1,
  },
  {
    inputDisplay: 'UTC',
    label: 'UTC',
    value: 'UTC',
    code: 'UTC',
    offset: '0000',
    mins: 0,
  },
  ...(isDST
    ? [
        {
          inputDisplay: 'US/Pacific (PDT)',
          label: 'US/Pacific (PDT)',
          value: 'US/Pacific',
          code: 'PDT',
          offset: '-0700',
          mins: -420,
        },
        {
          inputDisplay: 'America/Phoenix (MDT)',
          label: 'America/Phoenix (MDT)',
          value: 'America/Phoenix',
          code: 'MDT',
          offset: '-0600',
          mins: -360,
        },
        {
          inputDisplay: 'America/NewYork (EDT)',
          label: 'America/NewYork (EDT)',
          value: 'America/New_York',
          code: 'EDT',
          offset: '-0400',
          mins: -240,
        },
        {
          inputDisplay: 'America/Chicago (CDT)',
          label: 'America/Chicago (CDT)',
          value: 'America/Chicago',
          code: 'CDT',
          offset: '-0500',
          mins: -300,
        },
      ]
    : [
        {
          inputDisplay: 'US/Pacific (PST)',
          label: 'US/Pacific (PST)',
          value: 'US/Pacific',
          code: 'PST',
          offset: '-0800',
          mins: -480,
        },
        {
          inputDisplay: 'America/Phoenix (MST)',
          label: 'America/Phoenix (MST)',
          value: 'America/Phoenix',
          code: 'MST',
          offset: '-0700',
          mins: -420,
        },
        {
          inputDisplay: 'America/NewYork (EST)',
          label: 'America/NewYork (EST)',
          value: 'America/New_York',
          code: 'EST',
          offset: '-0500',
          mins: -300,
        },
        {
          inputDisplay: 'America/Chicago (CST)',
          label: 'America/Chicago (CST)',
          value: 'America/Chicago',
          code: 'CST',
          offset: '-0600',
          mins: -360,
        },
      ]),
  {
    inputDisplay: 'Asia/Kolkata (IST)',
    label: 'Asia/Kolkata (IST)',
    value: 'Asia/Kolkata',
    code: 'IST',
    offset: '+0530',
    mins: 330,
  },
  {
    inputDisplay: 'Asia/Shanghai (CST)',
    label: 'Asia/Shanghai (CST)',
    value: 'Asia/Shanghai',
    code: 'CST',
    offset: '+0800',
    mins: 480,
  },
];

export const accountMapping = {
  alpha: {
    'us-east-1': '793224092225',
  },
  beta: {
    'us-west-2': '938683183370',
  },
  gamma: {
    'us-west-2': '469184372683',
  },
  prod: {
    'af-south-1': '961183555229',
    'ap-east-1': '307765938305',
    'ap-northeast-1': '249865553350',
    'ap-northeast-2': '260202401307',
    'ap-northeast-3': '659735638099',
    'ap-south-1': '880779894562',
    'ap-southeast-1': '834662929603',
    'ap-southeast-2': '309507014018',
    'ca-central-1': '968536942630',
    'eu-central-1': '688132286395',
    'eu-north-1': '602075544943',
    'eu-south-1': '382111507803',
    'eu-west-1': '114740206335',
    'eu-west-2': '455360237659',
    'eu-west-3': '705957504632',
    'me-south-1': '378352635151',
    'sa-east-1': '593330473721',
    'us-east-1': '082613463631',
    'us-east-2': '760389689972',
    'us-west-1': '714018074412',
    'us-west-2': '650597233831',
    'ca-west-1': '740209138195',
  },
};

export const TIME_WINDOW_LIMIT = '3 days';

export const REQUEST_LOGS_INSTRUCTION_HEADER =
  'Instructions for requesting Logs';

export const REQUEST_LOGS_INSTRUCTIONS = [
  `Time window can be relative like '2 days ago' or absolute giving the start time and the end time.`,
  `For relative, you can select number of hours from the starttime for which you want to request the logs. e.g., 3 hours of log from time being 4 days ago.`,
  `For absolute, select the starttime and endtime to retreive the respective hourly logs that fall in that range.`,
  `NOTE: The maximum number of hourly logs that can be requested per request for a log group is of ${TIME_WINDOW_LIMIT}.`,
];

export const DOWNLOAD_LOGS_INSTRUCTION_HEADER = 'Download Logs';

export const DOWNLOAD_LOGS_INSTRUCTION_MESSAGE =
  'Follow instructions below to download logs in the ssh terminal if you are working on Customer ticket or in non-EU region:';

export const DOWNLOAD_LOGS_INSTRUCTION_WARNING =
  '**Please DO NOT download logs for EU regions (DUB/FRA/CDG/LHR) unless you are working on Customer ticket. \
                                                  You can view the logs in Tumbler or use the bark-swift script to view logs while working on auto cut tickets.**';

export const isValidRequestRange: DateRangePickerProps.ValidationFunction = (
  e
) => {
  const timeLogs = (start, end) => {
    const a = moment(end).isBefore(moment(start));
    return a;
  };

  // var startMoment, endMoment;
  // if ('absolute' === e.type) {
  //   startMoment = moment(e.startDate);
  //   endMoment = moment(e.endDate);
  // } else if ('relative' === e.type) {
  //   startMoment = moment().subtract(e.amount, e.unit);
  //   endMoment = moment();
  // }
  if ('absolute' === e.type) {
    const [a] = e.startDate.split('T'),
      [n] = e.endDate.split('T');
    if (!a || !n)
      return {
        valid: !1,
        errorMessage:
          'The selected date range is incomplete. Select a start and end date for the date range.',
      };
    if (
      moment(e.startDate).isAfter(moment()) ||
      moment(e.endDate).isAfter(moment())
    )
      return {
        valid: !1,
        errorMessage: 'Either of start date or end date cannot be future date.',
      };
    if (timeLogs(e.startDate, e.endDate))
      return {
        valid: !1,
        errorMessage: 'The end date must be greater than the start date.',
      };
  } else if ('relative' === e.type) {
    if (isNaN(e.amount))
      return {
        valid: !1,
        errorMessage:
          'The selected date range is incomplete. Specify a duration for the date range.',
      };
  }
  return { valid: !0 };
};


function normalizeToHours(amount, unit) {
  let normalizedAmount = amount;
  switch (unit) {
    case 'minute':
      normalizedAmount = amount / 60;
      break;
    case 'second':
      normalizedAmount = amount / 3600;
      break;
    case 'hour':
    default:
      break;
  }
  return normalizedAmount;
}

export const isValidRequestRangeForOnDemand: DateRangePickerProps.ValidationFunction = (e) => {
  const existingValidation = isValidRequestRange(e);
  if (!existingValidation.valid) {
    return existingValidation;
  }

  if ('absolute' === e.type) {
    const duration = moment.duration(moment(e.endDate).diff(moment(e.startDate)));
    if (duration.asHours() > 48) {
      return {
        valid: false,
        errorMessage: 'The time range cannot exceed 48 hours for On Demand data.',
      };
    }
  } else if ('relative' === e.type) {
    const normalizedAmount = normalizeToHours(e.amount, e.unit);
    if (['day', 'week', 'month', 'year'].includes(e.unit) || normalizedAmount > 48) {
      return {
        valid: false,
        errorMessage: 'The time range cannot exceed 48 hours for On Demand data',
      };
    }
  }

  return { valid: true };
};

export const convertTZ = (timestamp, tzOption) => {
  timestamp = parseInt(timestamp) * 1000;
  return moment(timestamp)
    .utcOffset(tzOption.mins)
    .format()
    .replace(new RegExp('[+-][0-9]{2}:[0-9]{2}|Z'), ' ' + tzOption.code);
};

export const addOptionToTop = (arr, label) => {
  const index = arr.findIndex((option) => option.label === label);
  if (index > 0) {
    const option = arr.splice(index, 1)[0];
    arr.unshift(option);
  }
};
