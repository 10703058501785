import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { useFlyout } from '../../hooks/useFlyout';
import { ESEC2 } from '../../models/types';
import { BGProgress } from './BGProgress';
import { ErrorMessageComponent } from "../../ErrorHandler/errorMessageComponent";
import { ApiList }from "../../ErrorHandler/utils";

// const healthMap = {
//   green: 'success',
//   red: 'danger',
//   yellow: 'warning',
//   unknown: 'unknown',
// } as { [key: string]: string };

interface ClusterOverviewProps {
  isLoading: boolean;
  domainIdentifier: string;
  ec2Instances: ESEC2[];
  esVersion: string;
  cellId: string;
  pinnedVFIDomain: string;
  pinnedVFIClient: string;
  IsSensitiveDomain: string;
  IsSensitiveClient: string;
  IsDomainIsolated: string;
  HAProxyVersion: string;
  HAProxyType: string;
  carbonDomain: string;
  errorMessage: string;
  handleRefreshData: Function;
  isFetching:boolean;
  dragonstoneDomain: string;
  IsRemotePublicationEnabled: string;
  DomainCreateDateTime: string;
}

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const ClusterOverview = (props: ClusterOverviewProps) => {
  console.log("value of prop", props.IsRemotePublicationEnabled)
  const setFlyout = useFlyout();

  useEffect(() => {
   console.log("remote :", props.IsRemotePublicationEnabled)
  }, [props.IsRemotePublicationEnabled]);

  const specialFlagsComponent = () => {
    let specialFlags = [];
    if (
      !(
        props.isLoading ||
        props.pinnedVFIDomain === 'No Pinned VFI' ||
        props.pinnedVFIDomain === 'N/A'
      )
    ) {
      specialFlags.push({
        description: `Pinned VFI (Domain):  ${props.pinnedVFIDomain}`,
        key: specialFlags.length + 1,
      });
    }
    if (
      !(
        props.isLoading ||
        props.pinnedVFIClient === 'No Pinned VFI' ||
        props.pinnedVFIClient === 'N/A'
      )
    ) {
      specialFlags.push({
        description: `Pinned VFI (Client):  ${props.pinnedVFIClient}`,
        key: specialFlags.length + 1,
      });
    }
    if (
      !(
        props.isLoading ||
        props.IsSensitiveDomain === 'False' ||
        props.IsSensitiveDomain === 'N/A'
      ) ||
      !(
        props.isLoading ||
        props.IsSensitiveClient === 'False' ||
        props.IsSensitiveClient === 'N/A'
      )
    ) {
      specialFlags.push({
        description: `Sensitive Customer:  True`,
        key: specialFlags.length + 1,
      });
    }
    if (
      !(
        props.isLoading ||
        props.IsDomainIsolated === 'False' ||
        props.IsDomainIsolated === 'N/A'
      )
    ) {
      specialFlags.push({
        description: `Domain Isolated: ${props.IsDomainIsolated}`,
        key: specialFlags.length + 1,
      });
    }
    return {
      component: (
        <Container>
          <SpaceBetween size="m">
            {specialFlags.map((specialFlag) => {
              return <li key={specialFlag.key}>{specialFlag.description}</li>;
            })}
          </SpaceBetween>
        </Container>
      ),
      count: specialFlags.length,
    };
  };

  return (
    <Container header={<Header variant="h2" actions={
            <SpaceBetween direction="horizontal" size="l">
              <Button iconName="refresh" disabled={props.isFetching || props.isLoading} onClick={props.handleRefreshData}/>
            </SpaceBetween>
          }>Domain overview </Header>}>
      {props.errorMessage ? (
    <ErrorMessageComponent  errorMessage={props.errorMessage} apiName={ApiList.CLUSTER_OVERVIEW}/>
  ) : (
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="ES version">
            {props.isLoading ? '--' : props.esVersion}
          </ValueWithLabel>
          <ValueWithLabel label="Carbon Domain">
            {props.isLoading ? '--' : props.carbonDomain || 'N/A'}
          </ValueWithLabel>
          <ValueWithLabel label="Remote Store Enabled Domain">
            {props.isLoading ? '--' : props.dragonstoneDomain || 'N/A'}
          </ValueWithLabel>
          <ValueWithLabel label="Remote Publication Enabled Domain">
            {props.isLoading ? '--' : props.IsRemotePublicationEnabled || 'N/A'}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Special Flags">
            {props.isLoading ? (
              '--'
            ) : (
              <Button
                variant="link"
                onClick={
                  () =>
                    setFlyout({
                      title: 'Special Flags',
                      size: 'm',
                      content: specialFlagsComponent().component,
                    })
                  // TODO: also look for client and domain level pinned sensitive for domains
                }
              >
                <span style={{ color: '#00a1c9' }}>
                  {specialFlagsComponent().count}
                </span>
              </Button>
            )}
          </ValueWithLabel>
           <ValueWithLabel label="B/G Progress">
            {props.isLoading ? (
              '--'
            ) : (
              <Button
                variant="link"
                onClick={() =>
                  setFlyout({
                    title: 'Progress',
                    size: 'l',
                    content: (
                      <BGProgress domainIdentifier={props.domainIdentifier} />
                    ),
                  })
                }
              >
                <span style={{ color: '#00a1c9' }}>{'Check progress'}</span>
              </Button>
            )}
          </ValueWithLabel>
          <ValueWithLabel label="Create Date / Time (UTC)">
            {props.isLoading ? '--' : props.DomainCreateDateTime?.split('T')[0] || 'N/A'}
            {' / '}
            {props.isLoading ? '--' : props.DomainCreateDateTime?.split('T')[1]?.split('.')[0] || 'N/A'}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Cell Id">
            {props.isLoading ? '--' : props.cellId}
          </ValueWithLabel>
          <ValueWithLabel label="HAProxy Version / Type">
            {props.isLoading ? '--' : props.HAProxyVersion}
            {' / '}
            {props.isLoading ? '--' : props.HAProxyType}
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
      )}
    </Container>
  );
};

export { ClusterOverview };
